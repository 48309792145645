import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./components/App.jsx";
import * as serviceWorker from "./utils/serviceWorker";

import "./styles/global.scss";

class AppContainer extends Component {
  render() {
    return (
      <Router>
        <App />
      </Router>
    );
  }
}

ReactDOM.render(<AppContainer />, document.getElementById("root"));

serviceWorker.register();
