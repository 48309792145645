/* eslint-disable require-jsdoc */

import React, { Component } from "react";

import imgAnnGrittStoerling from "../media/employees/ann-gritt-stoerling.jpg";
import imgBeateLangenberg from "../media/employees/beate-langenberg.jpg";
import imgCarlottaSedlatschek from "../media/employees/carlotta-sedlatschek.jpg";
import imgConsueloKleinReyes from "../media/employees/consuelo-klein-reyes.jpg";
import imgNinaGlas from "../media/employees/nina-glas.jpg";
import imgSabineRuppert from "../media/employees/sabine-ruppert.jpg";
import imgSandraHeyer from "../media/employees/sandra-heyer.jpg";
import imgTeam from "../media/team.jpeg";
import imgYellowDarkTitle from "../media/yellow-tile-dark.jpg";

import "../styles/team.scss";

const teamMembers = [
  {
    designatory: "Dr. med. vet.",
    name: "Sabine Ruppert",
    title: "Tierärztin",
    description: [
      "Studium der Veterinärmedizin in Gießen 1986 bis 1992",
      "Assistenz und Vertretungen in verschiedenen Kleintierpraxen",
      "Promotion 1996",
      "1996 Praxisgründung in Eschersheim",
    ],
    picture: imgSabineRuppert,
  },
  {
    designatory: "Dr. med. vet.",
    name: "Nina Glas",
    title: "Tierärztin",
    description: [
      "Studium der Veterinärmedizin in München 1993 bis 1999",
      "Promotion 2002",
      "Assistenz und Vertretungen in verschiedenen Kleintierpraxen",
      "2009 bis 2012 in der Tierarztpraxis Dr. Ruppert",
      "Seit Juni 2015 wieder im Team",
    ],
    picture: imgNinaGlas,
  },
  {
    designatory: "",
    name: "Carlotta Sedlatschek",
    title: "Tiermedizinische Fachangestellte",
    description: [
      "Ausbildung zur TMFA 2021 bis 2022 in der Tierarztpraxis Dr. Ruppert",
      "Seit Juni 2022 Weiterbeschäftigung als TMFA",
    ],
    picture: imgCarlottaSedlatschek,
  },
  {
    designatory: "",
    name: "Ann Grit Störling",
    title: "Tiermedizinische Fachangestellte",
    description: ["Ausbildung zur TMFA 2021 bis 2023", "Seit Mai 2023 als TMFA in der Tierarztpraxis Dr. Ruppert"],
    picture: imgAnnGrittStoerling,
  },
  {
    designatory: "",
    name: "Consuelo Klein Reyes",
    title: "Auszubildende",
    description: ["Ausbildung zur TMFA seit September 2022"],
    picture: imgConsueloKleinReyes,
  },
];

// eslint-disable-next-line require-jsdoc
class TeamMember extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  toggle() {
    this.setState({ active: !this.state.active });
  }

  render() {
    return (
      <div
        className={"team-member" + (this.state.active ? " active" : "")}
        onClick={this.toggle.bind(this)}
        style={{ backgroundImage: `url(${this.props.member.picture})` }}
      >
        {this.props.help && <div className="help">Karte anklicken für mehr</div>}
        <div className="team-member-title">
          {this.props.member.designatory} {this.props.member.name}
        </div>
        <div className="team-member-body">
          <h2 className="team-member-title text-emphasize">{this.props.member.title}</h2>
          <h2 className="team-member-name text-emphasize">
            {this.props.member.designatory} {this.props.member.name}
          </h2>
          <span className="team-member-description">
            {this.props.member.description.map((each, index) => {
              return <p key={index}>{each}</p>;
            })}
          </span>
        </div>
      </div>
    );
  }
}

class Team extends Component {
  render() {
    return (
      <div id="team" style={{ backgroundImage: `url(${imgYellowDarkTitle})` }}>
        <div className="team-heading">
          <span>Das Team</span>
          <img src={imgTeam} alt="" />
        </div>
        <div className="team-members">
          {teamMembers.map((each, index) => {
            return <TeamMember help={index === 0} member={each} key={index} />;
          })}
        </div>
      </div>
    );
  }
}

export default Team;
