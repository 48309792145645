import React, { Component } from "react";

class Legal extends Component {
  render() {
    return (
      <div id="legal">
        <p className="text-black">Tierärztliche Praxis für Kleintiere</p>
        <p className="text-black">Dr. Sabine Ruppert</p>
        <p className="text-black">Alt Eschersheim 29</p>
        <p className="text-black">60433 Frankfurt</p>
        <p className="text-black">Telefon: 069 / 52 52 01</p>
        <p className="text-black">Telefax: 069 / 95 29 79 40</p>
        <p className="text-black">
          E-mail: <a href="mailto:praxis@tierarzt-ruppert.de">praxis@tierarzt-ruppert.de</a>
        </p>
        <a className="text-black" href="http://www.tierarzt-ruppert.de">
          www.tierarzt-ruppert.de
        </a>
        <div className="text-black">
          <b>Approbation:</b> 1992 in Gießen
        </div>
        <div className="text-black">
          <b>Promotion:</b> 1996 in Gießen
        </div>
        <p className="text-black text-emphasize">Approbationsbehörde:</p>
        <p className="text-black">Bezirksregierung Gießen </p>
        <p className="text-black">Landgraf-Philipp-Straße 1-7 </p>
        <p className="text-black">35390 Gießen</p>
        <div className="text-black">
          <b>Steuernummer: </b>013 / 867 / 08220
        </div>
        <b className="text-black">Berufshaftpflichtversicherung: </b>
        <span className="text-black">INTER Versicherungsgruppe, Erzbergerstr. 9 - 15, 68165 Mannheim</span>
        <div className="text-black">
          <b>Berufsbezeichnung: </b>
          <span className="text-black">praktizierende Tierärztin, verliehen in der Bundesrepublik Deutschland</span>
        </div>
        <p className="text-black">Inhaltlich verantwortlich gemäß § 6 MDSTV:</p>
        <p className="text-black">Dr. Sabine Ruppert</p>
        <b className="text-black">Zuständige Landestierärztekammer Hessen und Schlichtungsbehörde:</b>
        <p className="text-black">Körperschaft des Öffentlichen Rechts</p>
        <p className="text-black">Bahnhofstraße 13</p>
        <p className="text-black">65527 Niedernhausen </p>
        <p className="text-black">Telefon: 06127/9075-0</p>
        <p className="text-black">Telefax: 06127/9075-23</p>
        <p className="text-black">
          Email: <a href="mailto:ltk-hessen@t-online.de">ltk-hessen@t-online.de</a>
        </p>
        <p className="text-black">
          Internet:{" "}
          <a href="http://www.ltk-hessen.de/" target="_blank" rel="noreferrer">
            www.ltk-hessen.de
          </a>
        </p>
        <b className="text-black">Berufliche Regelung:</b>
        <p className="text-black">
          Berufsordnung der Landestierärztekammer Hessen, Körperschaft des Öffentlichen Rechts, Bahnhofstraße 13, 65527
          Niedernhausen, Heilberufsgesetz für das Land Hessen. Die Regelungen sind bei der Landestierärztekammer Hessen
          abrufbar.{" "}
          <a href="http://bundesrecht.juris.de/bt_o/BJNR004160965.html" target="_blank" rel="noreferrer">
            Bundestierärzteordnung
          </a>
        </p>
        <b className="text-black">Honorierung unserer Leistungen:</b>
        <p className="text-black">
          Die Abrechnung erfolgt auf Grundlage der jeweils gültigen Fassung der Gebührenordnung für Tierärzte (GOT) und
          der jeweils gültigen Fassung der Arzneimittelpreisverordnung (AMPreisV). Diese kann auf Wunsch jederzeit
          eingesehen werden.
        </p>
        <a
          className="text-black"
          href="https://www.ltk-hessen.de/fileadmin/www_ltk_hessen_de/Dateien_neu/GOT/GOT_gueltig_ab_2022-11-22.pdf"
          target="_blank"
          rel="noreferrer"
        >
          Gebührenordnung (GOT)
        </a>
        <p className="text-black text-emphasize">Bestimmungen und Vorschriften:</p>
        <p className="text-black">
          Die Homepage der Tierarztpraxis Dr. Sabine Ruppert entspricht den Vorschriften und Bestimmungen für öffentlich
          abrufbare Tierarztinformationen.
        </p>
        <p className="text-black text-emphasize">
          Die gemachten Angaben stellen die Tierarztpraxis Dr. Sabine Ruppert nicht werbend heraus und begrenzen sich
          auf sachliche Inhalte.
        </p>
        <p className="text-black">Haftungshinweise:</p>
        <p className="text-black">
          Es wird keine Gewähr für die Richtigkeit und Vollständigkeit der Angaben auf diesen Internetseiten gegeben.
          Mit Urteil vom 12. Mai 1998 hat das Landgericht Hamburg entschieden, dass man durch die Anbringung und
          Verwendung von Links (Verknüpfungen) zu anderen Internetseiten deren Inhalte ggf. mitzuverantworten hat. Trotz
          sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den
          Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>
      </div>
    );
  }
}

export default Legal;
