function arraySliceGroups(array, groupCount) {
  const result = Array(groupCount)
    .fill()
    .map((_) => []);

  for (let item = 0; item < array.length; ++item) result[item % groupCount].push(array[item]);

  return result;
}

export default arraySliceGroups;
