let cookiesAllowed = false;

const allowCookies = () => {
  cookiesAllowed = true;
  localStorage.setItem("allow-cookies", true);
};

const loadCookies = () => {
  const allowCookies = localStorage.getItem("allow-cookies");

  cookiesAllowed = allowCookies === "true";
};

const setCookie = (key, value, expire = false) => {
  if (!cookiesAllowed) return;

  localStorage.setItem(key, value);

  if (expire !== false) {
    localStorage.setItem(`${key}-expire`, expire);
  }
};

const getCookie = (key) => {
  if (!cookiesAllowed) return null;

  const expire = localStorage.getItem(`${key}-expire`);

  if (expire) {
    try {
      if (Date.now() > new Date(parseInt(expire))) {
        console.log("remove");
        localStorage.removeItem(key);
        localStorage.removeItem(`${key}-expire`);
        return null;
      }
    } catch (e) {
      console.error("Invalid session storage expire date " + expire);
      return null;
    }
  }

  return localStorage.getItem(key);
};

export { cookiesAllowed, allowCookies, loadCookies, getCookie, setCookie };
