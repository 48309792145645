/* eslint-disable require-jsdoc */

import React, { Component } from "react";
import { Route } from "react-router-dom";

import Nav from "../components/Nav";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Home from "../components/Home";
import Legal from "../components/Legal";
import Popup from "../components/Popup";
import Privacy from "../components/Privacy";
import Links from "../components/Links";

import popups from "../data/popups.jsx";
import { loadCookies } from "../utils/cookies";

class App extends Component {
  constructor(props) {
    super(props);

    loadCookies();

    this.state = {
      showNav: false,
    };
  }

  openNav(e) {
    this.setState({ showNav: true });
  }

  closeNav(e) {
    this.setState({ showNav: false });
  }

  toggleNav(e) {
    this.setState({ showNav: !this.state.showNav });
  }

  render() {
    return (
      <React.Fragment>
        <div id="top">
          <Header toggleNav={this.toggleNav.bind(this)} />
          <Nav closeNav={this.closeNav.bind(this)} clickLink={this.closeNav.bind(this)} show={this.state.showNav} />
        </div>
        <div id="popup-box">
          {popups.map((p) => (
            <Popup {...p.props}>{p.body}</Popup>
          ))}
        </div>
        <div id="center">
          <Route exact path="/" component={Home} />
          <Route path="/links" component={Links} />
          <Route path="/legal" component={Legal} />
          <Route path="/privacy" component={Privacy} />
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
