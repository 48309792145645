/* eslint-disable require-jsdoc */

import React, { Component } from "react";

import { Slide } from "react-slideshow-image";

import imgYellowTiles from "../media/yellow-tile.jpg";
import imgCat01 from "../media/slideshow/cat-01.jpg";
import imgCat02 from "../media/slideshow/cat-02.jpg";
import imgDog01 from "../media/slideshow/dog-01.JPG";
import imgDog02 from "../media/slideshow/dog-02.jpg";

const images = [imgDog01, imgCat01, imgDog02, imgCat02];

const slideshowProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  scale: 0.4,
  arrows: false,
};

class Home extends Component {
  render() {
    return (
      <div id="about" style={{ backgroundImage: `url(${imgYellowTiles})` }}>
        <div id="showcase">
          <Slide {...slideshowProperties}>
            {images.map((each, index) => (
              <div key={index} className="slideshow-item">
                <div style={{ backgroundImage: `url(${each})` }}></div>
              </div>
            ))}
          </Slide>
        </div>
        <div id="introduction">
          <h1>Willkommen in unserer Tierarztpraxis für Kleintiere!</h1>
          <h2>
            Bei uns steht <span className="text-emphasize">Ihr Haustier</span> an erster Stelle.
          </h2>
          <p>
            Unser wichtigstes Ziel ist es, Ihr Tier und Sie kompetent und einfühlsam zu betreuen, weshalb wir uns in der
            Regel viel Zeit für Beratung, Vorsorge und Diagnostik nehmen. Auf Ihre Fragen und Wünsche gehen wir nach
            Möglichkeit ein.
          </p>
          <p>
            Die Praxis besteht seit 1996 und wurde seither ständig modernisiert. Wir sind technisch sehr gut
            ausgestattet und legen größten Wert auf regelmäßige Fortbildung des Teams.
          </p>
        </div>
      </div>
    );
  }
}

export default Home;
