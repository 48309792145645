/* eslint-disable require-jsdoc */

import React, { Component } from "react";

import About from "../components/About";
import Contact from "../components/Contact";
import Gallery from "../components/Gallery";
import Services from "../components/Services";
import Team from "../components/Team";

class Home extends Component {
  render() {
    return (
      <div id="home">
        <About />
        <Contact />
        <Services />
        <Team />
        <Gallery />
      </div>
    );
  }
}

export default Home;
