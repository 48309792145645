/* eslint-disable require-jsdoc */

import PropTypes from "prop-types";
import React, { Component } from "react";

import { setCookie, getCookie } from "../utils/cookies";

class Popup extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    const hide = getCookie(`${this.props.id}-hide`);

    this.state = {
      hide: hide ? hide === "true" : false,
    };
  }

  handleButtonClick(onClick, hide, hidePermanent, hidePermanentSec) {
    return (e) => {
      if (onClick) onClick();

      if (hidePermanent) {
        setCookie(`${this.props.id}-hide`, true, hidePermanentSec ? Date.now() + 1000 * hidePermanentSec : false);
        return this.setState({ hide: true }, onClick);
      } else if (hide) {
        return this.setState({ hide: true }, onClick);
      }
    };
  }

  render() {
    if (this.props.expires && this.props.expires < new Date()) return null;

    if (this.state.hide) return null;

    return (
      <div className="popup">
        <div className="title">{this.props.title}</div>
        <div className="body">{this.props.children}</div>
        <div className="buttons">
          {this.props.buttons &&
            this.props.buttons.map((b) => (
              <button onClick={this.handleButtonClick(b.onClick, b.hide, b.hidePermanent, b.hidePermanentSec)}>
                {b.text}
              </button>
            ))}
        </div>
      </div>
    );
  }
}

export default Popup;
