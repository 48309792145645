import React, { Component } from "react";

class Privacy extends Component {
  render() {
    return (
      <div id="privacy">
        <p className="text-black text-emphasize">Datenschutzhinweis</p>
        <p className="text-black">
          Die Rechtsgrundlage der Datenverarbeitung auf dieser Homepage ist Art. 6 Abs. 1 DSGVO. Die Übermittlung
          sämtlicher Daten ist derzeit noch ungesichert, d. h. sie erfolgt nicht über eine https-Verbindung. Daher
          könnten die übermittelten Daten auch unbefugten Dritten zur Kenntnis gelangen. Sämtliche erhobene Daten (s.
          u.) werden zum Zweck der Nutzung dieser Homepage und zum Schutz der Rechte und des (auch geistigen) Eigentums
          des Herausgebers verarbeitet. Diese Daten werden auch verarbeitet, um den Aufbau sowie die Aufrechterhaltung
          der Verbindung sowie ein problemloses Surfen auf dieser Homepage gewährleisten zu können. Die
          Datenverarbeitung erfolgt ebenso für administrative Zwecke zur Auswertung von Nutzungsstatistiken. Die
          benannten Zwecke begründen im Weiteren das Herausgeberinteresse an der Datenverarbeitung durch Nutzung dieser
          Homepage an sich. Sämtliche erhobene Daten und Informationen werden nicht genutzt, um den Nutzer dieser
          Homepage persönlich zu identifizieren, es sei denn, er tritt über die auf der Webseite angebotenen digitalen
          Möglichkeiten mit dem Herausgeber in Kontakt. Um die Nutzerdaten gegen zufällige oder vorsätzliche
          Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu
          schützen, kommen im Zugriffsbereich geeignete technische und organisatorische Sicherheitsmaßnahmen zum Einsatz
          (Art. 25 DSGVO). Auf dieser Homepage befinden sich Verlinkungen zu anderen Webseiten im Internet. Mit
          Anklicken dieser Verlinkungen verlässt der Nutzer diese Homepage. Der Herausgeber dieser Homepage übernimmt
          keine Haftung für Rechtsverletzungen, dahingestellt aus welchem Rechtsgrund, die mit dem Anklicken der
          Verlinkungen sowie dem Verlassen dieser Homepage einhergehen könnten. Insbesondere haftet der Herausgeber
          nicht für Datenschutzverletzungen, die sich allein durch Anklicken oder Mausberührung der Verlinkungen sowie
          darüber hinaus durch Besuch der extern verlinkten Seiten ergeben. Beim Aufrufen dieser Homepage werden durch
          den vom Nutzer verwendeten Browser automatisch verschiedene Informationen an den Server, von dem diese
          Homepage in dem Moment abgerufen wird, übertragen. Diese werden zumindest zeitweilig in einer Speicherdatei
          auf dem Server erfasst, einem sogenannten Log-File. Davon unbenommen sind Daten, die der Internetprovider des
          Nutzers auf gesetzlicher oder sonstiger Basis vom Nutzer erfasst und eventuell speichert. Vorbenannte
          Informationen sind z. B. die IP-Adresse des Nutzer-Endgerätes, Datum und Uhrzeit des Zugriffs, Name und
          Internetadresse der abgerufenen Dateien, die Ursprungswebseite, von der aus der Zugriff erfolgt, der
          zugreifende Browser und das Betriebssystem des Zugriffgerätes sowie der Name des Access-Providers des Nutzers.
          Auf dieser Homepage wird die Möglichkeit des Kontaktes per E-Mail geboten. Der Nutzer übermittelt seine Daten
          freiwillig. Dazu gehört in der Regel eine E-Mailadresse und/oder seine Telefonnummer bzw. evtl. weitere
          personenbezogene Daten, da der gewünschte Kontakt sonst nicht hergestellt werden kann. Mit dem Versenden der
          E-Mail zur Kontaktaufnahme erteilt der Nutzer dem Herausgeber die freiwillige Einwilligung zu dieser
          Kontaktaufnahme nach Art. 6 Abs. 1 Satz 1 a) DSGVO. Die übermittelten Kontaktdaten werden gelöscht, wenn die
          Nutzeranfrage abschließend bearbeitet wurde, bzw. unabhängig von einer erfolgten Kontaktaufnahme automatisch
          spätestens nach einem Monat.
        </p>
        <p className="text-black text-emphasize">Weiterhin hat der Nutzer als Betroffene(r) folgende Rechte:</p>
        <p className="text-black">
          Der Nutzer kann gemäß Art. 15 DSGVO Auskunft über seine vom Herausgeber verarbeiteten personenbezogenen Daten
          verlangen. Dies betrifft besonders den Verarbeitungszweck, die Kategorie der personenbezogenen Daten, die
          Kategorien von Empfängern, denen die Nutzerdaten übermittelt werden, die Speicherdauer der Nutzerdaten, die
          Nutzerrechte auf Berichtigung (Art. 16 DSGVO), Löschung (Art. 17 DSGVO), Einschränkung der Verarbeitung (Art.
          18 DSGVO) oder Widerspruch (Art. 21 DGSVO) sowie das Bestehen eines Beschwerderechts (Art. 77 DSGVO). Außerdem
          hat der Nutzer das Recht auf Datenübertragbarkeit (Art. 20 DSGVO), das heißt Übermittlung der vom Nutzer
          bereitgestellten Daten durch den Herausgeber der Seite in einem strukturierten, gängigen und
          maschinenlesebaren Format an sich selbst oder an einen anderen Verantwortlichen. Sollte der Nutzer dem
          Herausgeber eine Einwilligung zur Datenerhebung erteilt haben, kann diese gemäß Art. 7 Abs. 3 DSGVO jederzeit
          widerrufen oder abgeändert werden. Dies hat zur Folge, dass die Datenverarbeitung, die auf dieser Einwilligung
          beruhte, für die Zukunft nicht mehr in der vormaligen Weise fortgeführt werden darf.
        </p>
      </div>
    );
  }
}

export default Privacy;
