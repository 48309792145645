/* eslint-disable require-jsdoc */

import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";

const links = [
  {
    hash: "about",
    text: "Über Uns",
  },
  {
    hash: "contact",
    text: "Sprechzeiten & Anfahrt",
  },
  {
    hash: "services",
    text: "Unsere Leistungen",
  },
  {
    hash: "team",
    text: "Das Team",
  },
  {
    hash: "gallery",
    text: "Galerie",
  },
];

const NavLinks = ({ clickLink, linkProps, ...props }) => {
  return (
    <span className="nav-links">
      {links.map((link, index) => {
        return (
          <HashLink
            key={index}
            scroll={(el) => {
              el.scrollIntoView({ behaviour: "smooth" });
              window.scrollBy(0, -document.getElementById("header").offsetHeight);
            }}
            to={"/#" + link.hash}
            onClick={clickLink}
            {...linkProps}
          >
            {link.text}
          </HashLink>
        );
      })}
    </span>
  );
};

class Nav extends Component {
  render() {
    return (
      <div id="nav" className={this.props.show ? "active" : null}>
        <NavLinks linkProps={this.props.closeNav} clickLink={this.props.clickLink} />
      </div>
    );
  }
}

export default Nav;

export { NavLinks };
