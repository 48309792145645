import React from "react";

import imgTierpunkt from "../media/tierpunktbild.jpg";

const Links = (props) => (
  <div id="links">
    <p className="text text-emphasize">
      Hier finden Sie einige Links, die sich für Tierbesitzer als nützlich erwiesen haben:
    </p>
    <a className="tierpunkt" href="http://www.tier-punkt.de" target="_blank" rel="noreferrer">
      <img src={imgTierpunkt} alt="" />
      <p class="text">Aktuelle Infos des Tier Punkt, jeden Monat neu!</p>
    </a>
    <ul>
      <li>
        <a href="http://www.tasso.net/" target="_blank" rel="noreferrer">
          www.tasso.net
        </a>
      </li>
      <li>
        <a href="http://www.tsv-frankfurt.de/" target="_blank" rel="noreferrer">
          www.tsv-frankfurt.de
        </a>
      </li>
      <li>
        <a href="http://www.igel-insel.de/" target="_blank" rel="noreferrer">
          www.igel-insel.de
        </a>
      </li>
      <li>
        <a href="http://www.royal-canin.de/" target="_blank" rel="noreferrer">
          www.royal-canin.de
        </a>
      </li>
      <li>
        <a href="http://www.vet-concept.com/" target="_blank" rel="noreferrer">
          www.vet-concept.com
        </a>
      </li>
      <li>
        <a href="http://www.tierbestattung-im-rosengarten.de/" target="_blank" rel="noreferrer">
          www.tierbestattung-im-rosengarten.de
        </a>
      </li>
    </ul>
    <p className="text">Hier einige Bewertungen über die Tierarztpraxis Dr. Ruppert:</p>
    <ul>
      <li>
        <a
          href="http://www.jameda.de/frankfurt/tieraerzte/tieraerzte-fuer-kleintiere/dr-sabine-ruppert/uebersicht/80347634_1/"
          target="_blank"
          rel="noreferrer"
        >
          www.jameda.de
        </a>
      </li>
      <li>
        <a href="http://www.yelp.de/biz/dr-med-vet-sabine-ruppert-frankfurt-am-main" target="_blank" rel="noreferrer">
          www.yelp.de
        </a>
      </li>
    </ul>
    <p className="text">Außerdem unsere ersten Überweisungsadressen:</p>
    <ul>
      <li>
        <a href="http://www.tierklinik-bockenheim.de/" target="_blank" rel="noreferrer">
          www.tierklinik-bockenheim.de
        </a>{" "}
        - für stationäre Behandlungen und Notfälle
      </li>
      <li>
        <a href="http://www.tierklinik-hofheim.de/" target="_blank" rel="noreferrer">
          www.tierklinik-hofheim.de
        </a>{" "}
        - für onkologische Fälle
      </li>
      <li>
        <a href="http://www.tierarztpraxis-dr-loreth.de/" target="_blank" rel="noreferrer">
          www.tierarztpraxis-dr-loreth.de
        </a>{" "}
        - für Augenpatienten
      </li>
      <li>
        <a href="http://www.uriedelvet.de/" target="_blank" rel="noreferrer">
          www.uriedelvet.de
        </a>{" "}
        - für Vögel und Reptilien
      </li>
    </ul>
    <p class="text">Sowie einige Adressen, die für uns zuständige Behörden darstellen:</p>
    <ul>
      <li>
        <a href="http://www.bundestieraerztekammer.de/" target="_blank" rel="noreferrer">
          www.bundestieraerztekammer.de
        </a>
      </li>
      <li>
        <a href="http://www.ltk-hessen.de/" target="_blank" rel="noreferrer">
          www.ltk-hessen.de
        </a>
      </li>
    </ul>
  </div>
);

export default Links;
