/* eslint-disable require-jsdoc */

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Logo, MenuIcon } from "../components/Icons";
import { NavLinks } from "../components/Nav";

class Header extends Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  toggleNav(e) {
    if (typeof this.props.toggleNav === "function") {
      this.props.toggleNav(e);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="header-push" />
        <div id="header">
          <span className="wrapper-logo">
            <Link to="/">
              <Logo />
            </Link>
          </span>
          <span className="wrapper-button" onClick={this.toggleNav.bind(this)}>
            <MenuIcon />
          </span>
          <span className="wrapper-nav">
            <NavLinks />
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
