/* eslint-disable require-jsdoc */

import React, { Component } from "react";
import { IoIosAdd } from "react-icons/io";

import IMG_Examination from "../media/services/examination.jpg";
import IMG_Special from "../media/services/special-examination.jpg";
import imgLaboratory from "../media/services/laboratory-examination.jpeg";
import imgUltrasonic from "../media/services/ultrasonic.jpg";
import imgYellowTiles from "../media/yellow-tile.jpg";
import imgXraxExamination from "../media/services/xray-examination.jpg";
import imgMagnetFieldTherapy from "../media/services/magnet-field-therapy.jpg";
import imgStationaryCare from "../media/services/stationary-care.jpg";
import imgPetCare from "../media/services/pet-care.jpg";

const data = [
  [
    "Allgemeine Untersuchungen",
    IMG_Examination,
    "Wir führen allgmeine Untersuchungen an Ihren Tieren durch, wie Erstuntersuchungen von Welpen, Gesundheitschecks, geriatrische Untersuchungen für Senioren, etc.",
  ],
  [
    "Spezielle Untersuchungen",
    IMG_Special,
    "Wir führen spezielle Untersuchungen durch, wie Untersuchung der Ohren, der Haut, Lahmheitsdiagnostik und mehr. Zudem beraten wir Sie in allen Bereichen rund um's Tier wie Aufzucht, Haltung, Fütterung, Diätetik, Parasiten, Prophylaxe, Verhalten, etc..",
  ],
  [
    "Laboruntersuchungen",
    imgLaboratory,
    "Im Labor vorort führen wir Blut-, Urin- und Kotuntersuchungen durch. Des weiteren arbeiten wir mit Speziallaboren zusammen, zum Beispiel für internistische Funktionstests.",
  ],
  [
    "Röntgenuntersuchungen",
    imgXraxExamination,
    "Wir bieten Röntgenuntersuchungen mit digitaler Entwicklung vorort an.",
  ],
  [
    "Ultraschalluntersuchungen",
    imgUltrasonic,
    "Wir führen Standard-Ultraschalluntersuchungen am Bauchraum der Patienten durch.",
  ],
  [
    "Operationen",
    imgStationaryCare,
    "Wir führen Standard-Operationen durch wie Kastrationen, Weichteil- und Tumor OPs. Im Anschluss betreuen wir die Patienten in unserer Station.",
  ],
  ["Zahnsanierungen", null, ""],
  [
    "Heimtiere",
    imgPetCare,
    "Wir beraten Sie zu Fütterung und Haltung von Heimtieren, schneiden Krallen und Zähne und kümmern uns auch gerne um die kleinsten Patienten.",
  ],
  [
    "Weitere Services",
    imgMagnetFieldTherapy,
    "Zu unseren weiteren Services gehören Tierkennzeichnung mittels Microchip, Impfungen, EU-Heimtierausweise sowie Urlaubsvorbereitung. Außerdem führen wir Magnetfeldtherapie, Analdrüsenbehandlungen und vieles mehr durch.",
  ],
];

class Service extends Component {
  render() {
    return (
      <div className={"service-element " + (this.props.active ? "expanded" : "")}>
        <div className="service-head" onClick={this.props.onClick}>
          <IoIosAdd className="service-cross" />
          <div className="service-title">{this.props.title}</div>
        </div>
        <div className="service-body">
          {this.props.src && <img className="service-img" src={this.props.src} alt="example service" />}
          <div className="service-description">
            <p>{this.props.desc}</p>
          </div>
        </div>
      </div>
    );
  }
}

class Services extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeService: null,
    };
  }

  handleServiceClick(index) {
    this.setState({
      activeService: this.state.activeService === index ? null : index,
    });
  }

  render() {
    return (
      <div id="services" style={{ backgroundImage: `url(${imgYellowTiles})` }}>
        {data.map((each, index) => (
          <Service
            active={this.state.activeService === index}
            key={index}
            onClick={this.handleServiceClick.bind(this, index)}
            title={each[0]}
            src={each[1]}
            desc={each[2]}
          />
        ))}
      </div>
    );
  }
}

export default Services;
