/* eslint-disable require-jsdoc */

import React, { Component } from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";

import imgYellowDarkTiles from "../media/yellow-tile-dark.jpg";

class Contact extends Component {
  render() {
    return (
      <div id="contact" className="parallax" style={{ backgroundImage: `url(${imgYellowDarkTiles})` }}>
        <div id="location">
          <p className="text-white">
            Die Praxis befindet sich in <span>Alt Eschersheim 29, 60433 Frankfurt am Main (Eschersheim)</span>,
            verkehrsgünstig gelegen sowohl mit öffentlichen Verkehrsmitteln als auch mit dem Auto dank eigener
            Parkplätze im Hof.
          </p>
          <iframe
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAgPDqZwaGIamOTOicrT22IxYKMVH-BeK4&q=Tierarztpraxis+Dr.+Sabine+Ruppert,+Alt-Eschersheim+29,+60433+Frankfurt+am+Main,+Deutschland&maptype=roadmap"
            frameBorder="0"
            style={{}}
            allowFullScreen="false"
            aria-hidden="false"
            tabIndex="0"
          ></iframe>
        </div>
        <div id="office-hours">
          <h1 className="text-white text-emphasize">
            Sprechzeiten ausschließlich nach telefonischer Terminvereinbarung!
          </h1>
          <p className="text-white">Telefonisch erreichbar sind wir in der Regel:</p>
          <span>
            <span>montags</span>
            <span>09.00 - 12.00 Uhr</span>
            <span>16.00 - 18.30 Uhr</span>
          </span>
          <span>
            <span>dienstags</span>
            <span>vormittags OP</span>
            <span>17.00 - 20.00 Uhr</span>
          </span>
          <span>
            <span>mittwochs</span>
            <span>09.00 - 12.00 Uhr</span>
            <span>16.00 - 18.00 Uhr</span>
          </span>
          <span>
            <span>donnerstags</span>
            <span>09.00 - 12.00 Uhr</span>
            <span>16.00 - 18.00 Uhr</span>
          </span>
          <span>
            <span>freitags</span>
            <span>-</span>
            <span>16.00 - 18.30 Uhr</span>
          </span>
          <span>
            <span>samstags</span>
            <span>10.00 - 12.00 Uhr</span>
            <span>-</span>
          </span>
        </div>
        <div id="connect">
          <h2 className="text-white text-emphasize">Kontaktieren Sie uns</h2>
          <a href="mailto:praxis@tierarzt-ruppert.de">
            <FaEnvelope className="icon" />
            <span className="text-white">praxis@tierarzt-ruppert.de</span>
          </a>
          <a href="tel:+4969525201">
            <FaPhone className="icon" />
            <span className="text-white">069 52 52 01</span>
          </a>
        </div>
      </div>
    );
  }
}

/*
<div id="consultation-hours">
  <table>
    <tr>
      <th>Unsere Sprechzeiten für Kleintiere sind</th>
    </tr>
    <tr>
      <td>Montags</td>
      <td>von 10:00 - 12:00 und 16:00 - 18:00</td>
    </tr>
    <tr>
      <td>Dienstags</td>
      <td>vormittags Termine sonst 17:00 - 20:00</td>
    </tr>
    <tr>
      <td>Mittwochs</td>
      <td>von 10:00 - 12:00 und nachmittags Termine</td>
    </tr>
    <tr>
      <td>Donnerstags</td>
      <td>von 10:00 - 12:00 und nachmittags Termine</td>
    </tr>
    <tr>
      <td>Freitags</td>
      <td>vormittags geschlossen und von 16:00 - 18:30</td>
    </tr>
    <tr>
      <td>Samstags</td>
      <td>von 10:00 - 12:00 und nachmittags geschlossen</td>
    </tr>
  </table>
</div>
*/

export default Contact;
