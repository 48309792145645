import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaEnvelope, FaPhone } from "react-icons/fa";

class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <div className="footer-contact">
          <a className="footer-contact-entry" href="mailto:praxis@tierarzt-ruppert.de">
            <FaEnvelope />
            <span>praxis@tierarzt-ruppert.de</span>
          </a>
          <a className="footer-contact-entry" href="tel:+4969525201">
            <FaPhone />
            <span>069 52 52 01</span>
          </a>
        </div>
        <div className="footer-links">
          <Link to="/legal">Impressum</Link>
          <Link to="/privacy">Datenschutz</Link>
          <Link to="/links">Links</Link>
        </div>
      </div>
    );
  }
}

export default Footer;
