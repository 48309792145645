/* eslint-disable require-jsdoc */

import React, { Component } from "react";
import { FiRefreshCcw, FiZoomIn, FiZoomOut } from "react-icons/fi";

import arraySliceGroups from "../utils/arraySliceGroups";
import imgYellowTiles from "../media/yellow-tile.jpg";

import "../styles/gallery.scss";

class Gallery extends Component {
  static defaultImageGroupCount = 3;
  static maxImageGroupCount = 6;

  constructor(props) {
    super(props);

    this.state = {
      activeImage: null,
      imageSets: [],
      imageGroupCount: Gallery.defaultImageGroupCount,
    };

    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleReshuffle = this.handleReshuffle.bind(this);
  }

  componentDidMount() {
    this.setState({ images: Gallery.images }, this.shuffleImages);
  }

  loadImageSets() {
    const imageSets = arraySliceGroups(this.state.images, this.state.imageGroupCount);

    this.setState({ imageSets });
  }

  shuffleImages() {
    const images = this.state.images.sort(() => Math.random() - 0.5);

    this.setState({ images }, this.loadImageSets);
  }

  handleImageClick(image) {
    this.setState({
      activeImage: this.state.activeImage == image ? null : image,
    });
  }

  handleReshuffle() {
    this.shuffleImages();
  }

  handleZoom(amount = 1) {
    this.setState(
      {
        imageGroupCount: Math.max(1, Math.min(Gallery.maxImageGroupCount, this.state.imageGroupCount + amount)),
      },
      this.loadImageSets
    );
  }

  render() {
    return (
      <div id="gallery" style={{ backgroundImage: `url(${imgYellowTiles})` }}>
        <div className="heading">Galerie</div>
        <div className="tools">
          <button className="zoom-in" onClick={() => this.handleZoom(-1)}>
            <FiZoomIn />
          </button>
          <button className="zoom-out" onClick={() => this.handleZoom(1)}>
            <FiZoomOut />
          </button>
          <button className="reshuffle" onClick={this.handleReshuffle}>
            <FiRefreshCcw />
          </button>
        </div>
        <div className="image-box">
          {this.state.imageSets.map((set) => (
            <span
              className="image-box-set"
              style={{
                width: `calc(100% / ${this.state.imageGroupCount} - 6px)`,
              }}
            >
              {set.map((image) => (
                <span
                  className={"image-box-item" + (this.state.activeImage == image ? " active" : "")}
                  onClick={() => this.handleImageClick(image)}
                >
                  <img src={image} />
                </span>
              ))}
            </span>
          ))}
        </div>
      </div>
    );
  }
}

import image1 from "../media/employees/sabine-ruppert.jpg";
import image2 from "../media/employees/beate-langenberg.jpg";
import image3 from "../media/employees/carlotta-sedlatschek.jpg";
import image4 from "../media/employees/lisa-marie-jung.jpg";
import image5 from "../media/employees/nina-glas.jpg";
import image6 from "../media/employees/sandra-heyer.jpg";
import image7 from "../media/services/examination.jpg";
import image8 from "../media/services/laboratory-examination.jpeg";
import image9 from "../media/services/magnet-field-therapy.jpg";
import image10 from "../media/services/pet-care.jpg";
import image11 from "../media/services/services.jpg";
import image12 from "../media/services/special-examination.jpg";
import image13 from "../media/services/stationary-care.jpg";
import image14 from "../media/services/ultrasonic.jpg";
import image15 from "../media/services/xray-examination.jpg";
import image16 from "../media/slideshow/cat-01.jpg";
import image17 from "../media/slideshow/cat-02.jpg";
import image18 from "../media/slideshow/dog-01.JPG";
import image19 from "../media/slideshow/dog-02.jpg";
import image20 from "../media/other/2010-12-0023.jpg";
import image21 from "../media/other/2010-12-0025.jpg";
import image22 from "../media/other/2015-12-0014.jpg";
import image23 from "../media/other/cat-03.jpg";
import image24 from "../media/other/cat-04.jpg";
import image25 from "../media/other/IMG_0782.jpg";
import image26 from "../media/other/IMG_2057.jpeg";
import image27 from "../media/other/team_01.jpg";

Gallery.images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
];

export default Gallery;
