/* eslint-disable max-len */

import React from "react";

import { allowCookies } from "../utils/cookies";

const popups = [
  {
    props: {
      id: "cookie-popup",
      title: "Verwendung von Cookies",
      buttons: [
        {
          text: "Einverstanden",
          hidePermanent: true,
          onClick: allowCookies,
        },
        {
          text: "Ablehnen",
          hidePermanent: true,
        },
      ],
    },
    body: (
      <React.Fragment>
        Wir verwenden Cookies und den localStorage ihres Browsers, um die Nutzererfahrung auf unserer Website zu
        verbessern.
      </React.Fragment>
    ),
  },
];

export default popups;
