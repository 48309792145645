import React, { Component } from "react";
import { FaPhone } from "react-icons/fa";

import { IoMdPaw } from "react-icons/io";

class Logo extends Component {
  render() {
    return (
      <div className="logo">
        <IoMdPaw className="logo-icon" />
        <span className="logo-title">
          <span>Tierarztpraxis</span>
          <span>Dr. Sabine Ruppert</span>
          <span className="logo-phone">
            <FaPhone />
            <span>069 525201</span>
          </span>
        </span>
      </div>
    );
  }
}

class MenuIcon extends Component {
  render() {
    return (
      <div className="menu-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }
}

export { Logo, MenuIcon };
